import React, { SyntheticEvent } from 'react'
import { IMAGE_HOST, IMAGE_PATH } from '../constants'
import useConfig from '../hooks/useConfig'

type TypeResize = {
    width: string | Number
    height: string | Number
    format?: 'cover' | 'contain'
}
type TypeImageProps = {
    src: string
    alt?: string
    className?: string
    style?: any
    resize?: TypeResize
    onLoad?: (event: SyntheticEvent<HTMLImageElement, Event>) => void
}

export const Polaroid = (props: any) => <Image {...props} className={'bg-white p-2 shadow-md border'} />

const Image = React.memo(function Image(props: TypeImageProps) {
    const config = useConfig()
    let { src, alt, className, style, resize, onLoad } = props
    if (resize) {
        const { width, height, format = 'contain' } = resize
        if (width || height) {
            const extension = src.substring(src.lastIndexOf('.') + 1)
            const base = src.lastIndexOf('/') ? src.substring(src.lastIndexOf('/') + 1, src.lastIndexOf('.')) : src.substring(0, src.lastIndexOf('.'))
            const path = src.lastIndexOf('/') ? src.substring(0, src.lastIndexOf('/')) : '/'

            src = `${path}/r/${base}-${format}-${width}-${height}.${extension}`
        }
    }
    if (src.substring(0, IMAGE_PATH.length) === IMAGE_PATH) {
        src = src.substring(IMAGE_PATH.length)
    }

    return <img src={IMAGE_HOST + IMAGE_PATH + src} alt={alt || ''} className={className} style={style} onLoad={onLoad} />
})

export default Image
