import React from 'react'
import { TypeNotification } from '../types'
import { NOTIFICATION_NO_JPG_IMAGES_FOUND, NOTIFICATION_NON_JPG_IMAGES_FOUND } from '../constants'
import Button from './Button'
import { useApi, useNotifications } from './hooks'

type TypeNotificationProps = { notification: TypeNotification }

const notification_texts: any = {
    [NOTIFICATION_NO_JPG_IMAGES_FOUND]: {
        label: (n: TypeNotification) => (
            <>
                No valid image found for sequence <strong>{n.sequence?.label}</strong> in group <strong>{n.sequence_group?.label}</strong>
            </>
        ),
        description: (n: TypeNotification) => ``,
        action_label: (n: TypeNotification) => `Upload`,
    },
    [NOTIFICATION_NON_JPG_IMAGES_FOUND]: {
        label: (n: TypeNotification) => (
            <>
                {n.length} non JPG image{(n.length || 0) > 1 ? 's' : ''} found for sequence <strong>{n.sequence?.label}</strong> in group{' '}
                <strong>{n.sequence_group?.label}</strong>
            </>
        ),
        description: (n: TypeNotification) => ``,
        action_label: (n: TypeNotification) => `Convert to JPG`,
        action_label_loading: (n: TypeNotification) => `Converting to JPG`,
        action_label_success: (n: TypeNotification) => `Converted to JPG`,
    },
}
const DisplayNotificationText = (props: any) => {
    const { notification, prop } = props

    {
        /* console.log({ notification }, prop, notification_texts[notification.type_id], notification_texts[notification.type_id]?.[prop]) */
    }
    return notification_texts[notification.type_id]?.[prop](notification) || null
}
const NotificationActionButton = (props: TypeNotificationProps) => {
    const { notification } = props
    const convertApi = useApi()
    const {refresh} = useNotifications()
    const onClick = () => {
        if (notification.type_id === NOTIFICATION_NON_JPG_IMAGES_FOUND) {
            convertApi.post('image/convert', notification)
            .then(() => {
                refresh()
            })
        }
    }
    return (
        <Button isLoading={convertApi.isLoading} onClick={onClick}>
            <DisplayNotificationText
                notification={notification}
                prop={convertApi.isLoading ? 'action_label_loading' : (convertApi.isSuccess && 'action_label_success') || 'action_label'}
            />
        </Button>
    )
}

const Notification = React.memo(function Notification(props: TypeNotificationProps) {
    const { notification } = props

    return (
        <div className="flex justify-between">
            <div>
                <h4>
                    <DisplayNotificationText notification={notification} prop="label" />
                </h4>
                <div>
                    <DisplayNotificationText notification={notification} prop="description" />
                </div>
            </div>
            <NotificationActionButton notification={notification} />
        </div>
    )
})

export default Notification
