export const IMAGE_HOST = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8010/'
export const IMAGE_PATH = process.env.NODE_ENV === 'production' ? 'static/image/' : 'static/image/'
export const FILE_URL = process.env.NODE_ENV === 'production' ? '/static/' : 'http://localhost:8010/static/'
export const PAGE_URL = process.env.NODE_ENV === 'production' ? '/static/' : 'http://localhost:8010/static/'
export const API_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8010/'

export const dummyFn = () => {}
export const INPUT__DEBOUNCE_TIME = 100

export const NOTIFICATION_NO_JPG_IMAGES_FOUND = 'NOTIFICATION_NO_JPG_IMAGES_FOUND'
export const NOTIFICATION_NON_JPG_IMAGES_FOUND = 'NOTIFICATION_NON_JPG_IMAGES_FOUND'

export const IMAGE_SIZE_ARRAY = [
    { width: 640, height: 480 },
    { width: 1024, height: 768 },
    { width: 1920, height: 1080, original: true },
]
export const THUMBNAILS_SIZE = 135

const constants = {
    IMAGE_HOST,
    IMAGE_PATH,
    FILE_URL,
}

export default constants
