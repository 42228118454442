import React, { useEffect, useLayoutEffect, useState } from 'react'
import { IMAGE_HOST, IMAGE_PATH } from '../constants'
import { TypeClickZone } from '../types'
import { useConfig, useData, useDimensions, useId } from './hooks'

type TypeResize = {
    width: string | Number
    height: string | Number
    format?: 'cover' | 'contain'
}
type TypeObjectProps = {
    src: string
    alt?: string
    className?: string
    style?: any
    resize?: TypeResize
    onClick?: Function
    click_zone?: TypeClickZone
    image_nb?: string
    onLoad?: Function
}

const LazySVG = React.memo(function LazySVG(props: TypeObjectProps) {
    // const config = useConfig()
    const id = useId()
    let { src, alt, className, style, click_zone, image_nb } = props
    if (src.substring(0, IMAGE_PATH.length) === IMAGE_PATH) {
        src = src.substring(IMAGE_PATH.length)
    }
    const [innerHTML, setInnerHTML] = useState<any>(null)

    useEffect(() => {
        fetch(IMAGE_HOST + IMAGE_PATH + src)
            .then((r) => r.text())
            .then((payload) => {
                setInnerHTML(payload)
                props.onLoad?.(image_nb)
            })
    }, [src])

    // const [svgRef, dimensions] = useDimensions({ liveMeasure: true })
    // console.log({ dimensions })
    // useLayoutEffect(() => {
    // console.log({ dimensions })
    // }, [])
    useLayoutEffect(() => {
        const handleClick = (e: any) => {
            props.onClick?.(e)
        }
        document.querySelector(`#${id} path`)?.addEventListener('click', handleClick)
        return () => document.querySelector(`#${id} path`)?.addEventListener('click', handleClick)
    }, [innerHTML])

    return (
        <>
            <div
                dangerouslySetInnerHTML={{ __html: innerHTML }}
                className={className}
                style={style}
                id={id}
                data-click-zone-id={click_zone?.id}
                data-image-nb={image_nb}
            />
            {/* <div style={{ color: 'blue', width: '20px', height: '20px', position: 'absolute' }} className="click-zone-marker" /> */}
        </>
    )
})

export default LazySVG
