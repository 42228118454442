import { useState, useMemo, ChangeEvent } from 'react'
import { INPUT__DEBOUNCE_TIME } from '../constants'
import useDebounce from './useDebounce'

export type TypeUseInputReturnedValue = {
    value: any
    debounced_value: any
    onChange: (e: ChangeEvent<HTMLInputElement> | any) => void
    setValue: Function
    reset: Function
    bind: {
        value: any
        onChange: (e: ChangeEvent<HTMLInputElement> | any) => void
    }
}

type TypeUseInput = (initialValue?: any, debounce?: boolean, delay?: number) => TypeUseInputReturnedValue

const useInput: TypeUseInput = (initialValue) => {
    let delay = 0
    let onChangeHook: any
    if (!Array.isArray(initialValue) && typeof initialValue === 'object' && initialValue !== null) {
        delay = typeof initialValue.delay !== 'undefined' ? initialValue.delay : (initialValue.debounce && INPUT__DEBOUNCE_TIME) || 0
        onChangeHook = initialValue.onChange
        initialValue = initialValue.value || ''
    } else {
        initialValue = initialValue || ''
    }
    const [value, setValue] = useState(initialValue)
    const debounced_value = useDebounce(value, delay)

    return useMemo(() => {
        const onChange = (e: ChangeEvent<HTMLInputElement> | any): void => {
            if (typeof e.value !== 'undefined') {
                setValue(e.value)
                typeof onChangeHook === 'function' && onChangeHook(e.value)
            } else if (e.target && typeof e.target.value !== 'undefined') {
                setValue(e.target.value)
                typeof onChangeHook === 'function' && onChangeHook(e.target.value)
            } else if (typeof e.target !== 'undefined') {
                setValue(e.target.innerText)
                typeof onChangeHook === 'function' && onChangeHook(e.target.innerText)
            }
        }
        return {
            value,
            debounced_value,
            onChange,
            setValue: (value: any) => {
                setValue(value)
            },
            reset: () => setValue(initialValue),
            bind: { value, onChange },
        }
    }, [value])
}
export const useSelect = (initialValue?: any) => {
    let onChangeHook: any
    if (!Array.isArray(initialValue) && typeof initialValue === 'object' && initialValue !== null) {
        onChangeHook = initialValue.onChange
        initialValue = initialValue.value || ''
    } else {
        initialValue = initialValue || ''
    }
    const [value, setValue] = useState(initialValue)
    return {
        value,
        onChange: (e: any) => {
            if (typeof e.value !== 'undefined') {
                setValue(e.value)
                typeof onChangeHook === 'function' && onChangeHook(e.value)
            } else if (e.target && typeof e.target.value !== 'undefined') {
                setValue(e.target.value)
                typeof onChangeHook === 'function' && onChangeHook(e.target.value)
            } else if (typeof e.target !== 'undefined') {
                setValue(e.target.innerText)
                typeof onChangeHook === 'function' && onChangeHook(e.target.innerText)
            }
        },
    }
}

export default useInput
