import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faExclamationTriangle,
    faHome,
    faEdit,
    faCog,
    faMinusCircle,
    faPlusCircle,
    faCheck,
    faUpload,
    faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'

library.add(faExclamationTriangle, faHome, faEdit, faCog, faMinusCircle, faPlusCircle, faCheck, faUpload, faExternalLinkAlt)

export const RemoveIcon = (props: any) => (
    <Icon name="minus-circle" {...props} className={'text-gray-500 hover:text-red-600 cursor-pointer ' + (props.className || '')} />
)
export const AddIcon = (props: any) => <Icon name="plus-circle" {...props} className={'text-gray-500 cursor-pointer ' + (props.className || '')} />

type TypeIconProps = any

const Icon = React.memo(function Icon(props: TypeIconProps) {
    const { name } = props

    return (name && <FontAwesomeIcon {...props} icon={name} className={'icon ' + (props.className || '')} />) || null
})

export default Icon
