import React from 'react'
import useNotifications from '../hooks/useNotifications'
import Page from './Page'
import Notification from './Notification'

type TypeNotificationsProps = any

const Notifications = (props: TypeNotificationsProps) => {
    const { notification_array } = useNotifications()

    return (
        <Page>
            <ul>
                {notification_array.map((notification, index) => (
                    <li key={index} className="mb-3">
                        <Notification notification={notification} />
                    </li>
                ))}
            </ul>
        </Page>
    )
}

export default Notifications
