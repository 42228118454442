import { useState, useEffect } from 'react'
import { INPUT__DEBOUNCE_TIME, dummyFn } from '../constants'

function useDebounce(value: any, delay: number = INPUT__DEBOUNCE_TIME) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(
        () => {
            if (delay !== 0) {
                // Update debounced value after delay
                const handler = setTimeout(() => {
                    setDebouncedValue(value)
                }, delay)

                // Cancel the timeout if value changes (also on delay change or unmount)
                // This is how we prevent debounced value from updating if value is changed ...
                // .. within the delay period. Timeout gets cleared and restarted.
                return () => {
                    clearTimeout(handler)
                }
            }
            return dummyFn
        },
        [value, delay] // Only re-call effect if value or delay changes
    )

    return debouncedValue
}

export default useDebounce
