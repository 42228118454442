import React, { useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import { PAGE_URL } from '../constants'
import { TypeStaticPage } from '../types'
import { useConfig } from './hooks'

const StaticPage = React.memo(function (props: any) {
    const { id } = props
    const { static_page_array = [] } = useConfig()
    const static_page = static_page_array.find((sp: TypeStaticPage) => sp.id === id)
    // const history = useHistory()
    useEffect(() => {
        static_page && (document.location.href = PAGE_URL + static_page?.path)
        // static_page && history.push(PAGE_URL + static_page?.path)
    })
    return <></>
})

export default StaticPage
