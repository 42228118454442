import { useRef } from 'react'
import * as uuid from 'uuid'

const useId = () => {
    const id = useRef<any>(null)
    !id.current && (id.current = uuid.v4())
    return 'a' + id.current
}

export default useId
