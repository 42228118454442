import React from 'react'

type TypeFooterProps = any

const Footer = React.memo(function Footer(props: TypeFooterProps) {
    return (
        <footer className="w-full px-4 py-6 bg-gray-800 text-white">
            <div className="container mx-auto">&copy; Sister Smile Limited 2020-{new Date().getFullYear()}</div>
        </footer>
    )
})

export default Footer
