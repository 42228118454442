import { rotate } from 'fp-ts/lib/ReadonlyArray'
import React from 'react'
import useGetParams from '../hooks/useGetParams'
import Image from './Image'

type TypeCompassProps = {
    currentImage: number
}

const Compass = React.memo(function Compass(props: TypeCompassProps) {
    const { currentImage } = props
    const { reverse_rotation, end, compass_offset } = useGetParams()

    const rotate_value = (currentImage === 0 ? 0 : (currentImage / end) * 360) + (compass_offset || 0)
    // const transform_style_candidate = 'rotate(' + (reverse_rotation ? rotate_value * -1 : rotate_value) + 'deg)'
    const transform_style_candidate = 'rotate(' + rotate_value + 'deg)'

    return (
        <>
            <Image src={'compass.png'} style={{ transform: transform_style_candidate }} className="fixed bottom-0 right-0 p-4 h-36 w-36" />
        </>
    )
})

export default Compass
