import { constants } from 'buffer'
import React from 'react'
import useConfig from '../hooks/useConfig'
import ImageMenuZone from './ImageMenuZone'
import Image from './Image'

type TypeImageMenuProps = {
    id: string
}

const ImageMenu = (props: TypeImageMenuProps) => {
    const { image_menu_zone_array, image_menu_array } = useConfig()
    const { id } = props
    const image_menu = image_menu_array.find((im) => im.id === id)

    return (
        <div className="relative">
            {image_menu_zone_array.map((imz) => (
                <ImageMenuZone key={imz.id} image_menu_zone={imz} />
            ))}
            {(image_menu && (
                <Image
                    src={'image-menu/' + image_menu.id + '.' + image_menu.extension + '?' + image_menu.key}
                    alt={image_menu.alt}
                    className="max-w-screen max-h-screen"
                />
            )) ||
                null}
        </div>
    )
}

export default ImageMenu
