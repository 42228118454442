import { useState, useEffect, useMemo } from 'react'
import { FILE_URL, API_URL } from '../constants'

export type TypeUseData<D> = {
    data: D | null
    isLoading: boolean
    refresh: Function
    setData: Function
}

const checkConditionArray = (arr: any[]) => arr.every((i) => !!i)

const useData = <D>(params: any, condition_array?: boolean[]): TypeUseData<D> => {
    const { url, src } = params
    const [data, setData] = useState<null | D>(null)
    const cond = condition_array ? checkConditionArray(condition_array) : true
    const [isLoading, setIsLoading] = useState(false)

    const doFetch = () => {
        cond && setIsLoading(true)
        cond &&
            fetch(src ? FILE_URL + src : API_URL + url)
                .then((r) => r.json())
                .then((r) => {
                    setIsLoading(false)
                    setData(r)
                })
    }

    const refresh = () => {
        doFetch()
    }

    useEffect(() => {
        doFetch()
    }, [url, src])

    return useMemo<TypeUseData<D>>(
        () => ({
            data,
            setData,
            refresh,
            isLoading,
        }),
        [data]
    )
}

export default useData
