import React from 'react'
import { THUMBNAILS_SIZE } from '../constants'
import { TypeSequence } from '../types'
import { Polaroid } from './Image'

type TypeImageStackProps = { sequence: TypeSequence }

const ImageStack = React.memo(function ImageStack(props: TypeImageStackProps) {
    const { sequence } = props
    return (
        <div className="image-stack" style={{ width: THUMBNAILS_SIZE, height: THUMBNAILS_SIZE }}>
            <Polaroid
                src={`sequence/${sequence.id}/${String(2).padStart(4, '0')}.jpg`}
                resize={{ format: 'cover', width: THUMBNAILS_SIZE, height: THUMBNAILS_SIZE }}
            />
            <Polaroid
                src={`sequence/${sequence.id}/${String(1).padStart(4, '0')}.jpg`}
                resize={{ format: 'cover', width: THUMBNAILS_SIZE, height: THUMBNAILS_SIZE }}
            />
            <Polaroid
                src={`sequence/${sequence.id}/${String(0).padStart(4, '0')}.jpg`}
                resize={{ format: 'cover', width: THUMBNAILS_SIZE, height: THUMBNAILS_SIZE }}
            />
        </div>
    )
})

export default ImageStack
