import React from 'react'
import { useHistory, Link, useRouteMatch } from 'react-router-dom'
import { API_URL } from '../constants'
import { TypeAdminContext } from '../contexts'
import useAdmin from '../hooks/useAdmin'
import useConfig from '../hooks/useConfig'
import useNotifications from '../hooks/useNotifications'
import useInput, { useSelect } from '../hooks/useInput'
import Icon from './Icon'
//import useFetch from '../hooks/useFetch'
import { TypeSequenceGroup, TypeConfig } from '../types'
import useLocalStorage from '../hooks/useLocalStorage'
import Input from './Input'
import Button from './Button'

const useCheck = (image_sequence_group: string) => {
    // const check = useFetch<any>({ url: API_URL + 'check_building/' + image_menu_zone_id })
}

const AdminBar = React.memo(function AdminBar(props: any) {
    const history = useHistory()
    const { selected, setSelected, edit, displaying_sequence, sequence_overlay, setAdmin } = useAdmin()
    const { setConfig, ...config } = useConfig()
    const { image_menu_zone_array, base } = config
    const { notification_array } = useNotifications()

    const [loggedIn, setLoggedIn] = useLocalStorage('logged_in')

    const ImageMenuZoneSelect = useSelect({
        onChange: (value: any) => {
            setSelected({ type: 'image-menu-zone', ...image_menu_zone_array.find((b) => b.id === value) })
        },
    })

    const updateImageMenuZoneValue = (prop: string) => (value: any) => {
        setConfig((c: TypeConfig) => ({
            ...c,
            image_menu_zone_array: c.image_menu_zone_array.reduce((acc: TypeSequenceGroup[], b) => {
                acc.push(b.id === selected.id ? { ...b, [prop]: value } : b)
                return acc
            }, []),
        }))
    }

    console.log({ selected })

    return (
        (loggedIn === '1' && (
            <div id="" className="flex justify-between fixed top-0 z-50 w-screen h-12 px-3 items-center bg-gray-800 text-white">
                <div className="flex">
                    <Link to={base} className="cursor-pointer mx-3">
                        <Icon name="home" /> Home
                    </Link>
                    <div
                        onClick={() => {
                            setAdmin((a: TypeAdminContext) => ({ ...a, edit: !a.edit }))
                        }}
                        className="cursor-pointer mx-3"
                    >
                        <Icon name="edit" className="cursor-pointer" color="primary" /> Edit: {edit ? 'On' : 'Off'}
                    </div>
                    {(displaying_sequence && (
                        <Button onClick={() => setAdmin((c: any) => ({ ...c, sequence_overlay: !sequence_overlay }))}>Sequence Overlay</Button>
                    )) ||
                        null}
                </div>
                {(edit && (
                    <>
                        <select {...ImageMenuZoneSelect}>
                            <option value="">Select zone</option>
                            {image_menu_zone_array.map((b) => (
                                <option key={b.id} value={b.id}>
                                    {b.label}
                                </option>
                            ))}
                        </select>
                        {(selected && selected.type === 'image-menu-zone' && (
                            <React.Fragment key={selected.id}>
                                Zone {selected?.id}
                                Left <AdminNumberSelect defaultvalue={selected.left} onChange={updateImageMenuZoneValue('left')} />
                                Top <AdminNumberSelect defaultvalue={selected.top} onChange={updateImageMenuZoneValue('top')} />
                                Width <AdminNumberSelect defaultvalue={selected.width} onChange={updateImageMenuZoneValue('width')} />
                                Height <AdminNumberSelect defaultvalue={selected.height} onChange={updateImageMenuZoneValue('height')} />
                            </React.Fragment>
                        )) ||
                            null}
                        <button
                            onClick={() => {
                                fetch(API_URL + 'config', {
                                    method: 'post',
                                    headers: {
                                        Accept: 'application/json',
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(config),
                                })
                                    .then((r) => r.json())
                                    .then((r) => console.log(r.result))
                            }}
                        >
                            Save
                        </button>
                    </>
                )) ||
                    null}
                <div className="flex">
                    <Link to={base + 'settings'} className="cursor-pointer mx-3">
                        <Icon name="cog" className="cursor-pointer mx-3" />
                    </Link>
                    {(notification_array.length && (
                        <div onClick={() => history.push(base + 'notifications')}>
                            <Icon name={'exclamation-triangle'} className="cursor-pointer" />
                        </div>
                    )) ||
                        null}
                </div>
            </div>
        )) ||
        null
    )
})

const AdminNumberSelect = (props: any) => {
    const { defaultvalue = 0 } = props
    const numberValue = useInput({ value: Number(defaultvalue), onChange: props.onChange })

    return <Input type="number" min="0" {...numberValue.bind} />
}

export default AdminBar
