import React, { useState, useMemo } from 'react'

const isJSON = (obj: any) => {
    obj = (typeof obj !== 'string' && JSON.stringify(obj)) || obj
    try {
        obj = JSON.parse(obj)
    } catch (e) {
        return false
    }
    return typeof obj === 'object' && obj !== null
}

type TypeUseLocalStorageParams =
    | string
    | {
          key: string
          defaultValue?: any
      }

const useLocalStorage = (params: TypeUseLocalStorageParams) => {
    let key: string, defaultValue: any
    if (typeof params === 'object' && params !== null) {
        ;({ key, defaultValue } = params)
    } else {
        key = params
    }
    let initialData = localStorage.getItem(key)
    if (initialData && isJSON(initialData)) {
        initialData = JSON.parse(initialData)
    }
    const [data, setData] = useState<any>(initialData || defaultValue)

    return useMemo(() => {
        const set = (param1: any, param2?: any) => {
            if (param2) {
                if (typeof param1 === 'string') {
                }
            } else {
                if (typeof param1 === 'string') {
                    localStorage.setItem(key, param1)
                    setData(param1)
                }
                if (typeof param1 === 'object') {
                    localStorage.setItem(key, JSON.stringify(param1))
                    setData(param1)
                }
            }
        }
        return [data, set]
    }, [data])
}

export default useLocalStorage
