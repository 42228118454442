import React from 'react'
import { TypeConfigContext, TypeNotification } from './types'

const dummyFn = () => {}

export const defaultConfigContextValue: TypeConfigContext = {
    base: '/',
    title: '',
    root: { image_menu_id: '0' },
    image_start: 0,
    image_end: 30,
    display_logo: false,
    color_array: [],
    image_array: [],
    file_array: [],
    image_menu_array: [{ id: '0', extension: '.jpg', src: './', alt: '', key: '1', color: '#FFFFFF' }],
    image_menu_zone_array: [],
    static_page_array: [],
    sequence_group_array: [{ id: '1', label: '1' }],
    sequence_array: [],
    click_zone_array: [],
    setConfig: dummyFn,
    refresh: dummyFn,
    saveConfig: dummyFn,
}
export const ConfigContext = React.createContext(defaultConfigContextValue)

export type TypeAdminContext = {
    enabled: boolean
    edit: boolean
    selected: any
    displaying_sequence: boolean
    sequence_overlay: boolean
    setSelected: Function
    setAdmin: Function
}
export const defaultAdminContextValue: TypeAdminContext = {
    enabled: true,
    edit: false,
    selected: null,
    displaying_sequence: false,
    sequence_overlay: false,
    setSelected: dummyFn,
    setAdmin: dummyFn,
}
export const AdminContext = React.createContext<TypeAdminContext>(defaultAdminContextValue)

export type TypeNotificationsContext = {
    notification_array: TypeNotification[]
    setNotificationArray: Function
    refresh: Function
}

export const defaultNotificationsContextValue: TypeNotificationsContext = {
    notification_array: [],
    setNotificationArray: dummyFn,
    refresh: dummyFn,
}

export const NotificationsContext = React.createContext<TypeNotificationsContext>(defaultNotificationsContextValue)
