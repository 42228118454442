import React from 'react'
import { useHistory } from 'react-router-dom'
import useAdmin from '../hooks/useAdmin'
import useConfig from '../hooks/useConfig'
import { TypeImageMenuZone } from '../types'

type TypeImageMenuZoneProps = {
    image_menu_zone: TypeImageMenuZone
}

const ImageMenuZone = React.memo(function ImageMenuZone(props: TypeImageMenuZoneProps) {
    const { image_menu_zone } = props
    const { left, top, width, height, id, target } = image_menu_zone
    const { edit, setSelected } = useAdmin()
    const { base } = useConfig()
    const history = useHistory()
    const goToTarget = () => {
        if (target?.sequence_group_id) {
            history.push(`${base}${target.sequence_group_id}`)
        }
    }
    return (
        (void 0 !== left && void 0 !== top && width && height && (
            <div
                onClick={(edit && (() => setSelected({ type: 'image-menu-zone', ...image_menu_zone }))) || goToTarget}
                style={{
                    position: 'absolute',
                    left: left + '%',
                    top: top + '%',
                    width: width + '%',
                    height: height + '%',
                    backgroundColor: image_menu_zone.color,
                }}
                className={edit ? 'cursor-pointer bg-blue-800 opacity-0 hover:blue-400 hover:opacity-0 shadow' : 'cursor-pointer hover:opacity-0 opacity-0'}
            >
                {/* {image_menu_zone.label} */}
            </div>
        )) ||
        null
    )
})

export default ImageMenuZone
