import React from 'react'
import Button from './Button'
import { useApi } from './hooks'
import Page from './Page'

const Import = React.memo(function Import() {
    const importApi = useApi()
    return (
        <>
            <Page>
                <Button
                    onClick={() => {
                        importApi.post('import')
                    }}
                    {...importApi.bind}
                >
                    Import
                </Button>
            </Page>
        </>
    )
})

export default Import
