import React from 'react'
import { useAdmin } from './hooks'
import Footer from './Footer'

type TypePageProps = any

const Page = React.memo(function Page(props: TypePageProps) {
    const admin = useAdmin()
    return (
        <>
            <div className={'h-full w-full container mx-auto px-4 pb-12 pt-6' + (admin.enabled ? ' mt-12' : '')}>{props.children}</div>
            <Footer />
        </>
    )
})

export default Page
