import React from 'react'
import Spinner from './Spinner'

type TypeButtonProps = any

const Button = React.memo(function Button(props: TypeButtonProps) {
    const { color = 'default' } = props
    const { isLoading, disabled = isLoading, size, ...rest } = props

    let btn_class_name = ''
    switch (color) {
        case 'default':
            btn_class_name = 'btn-default'
            break
        case 'primary':
            btn_class_name = 'btn-primary'
            break
    }
    const className = 'flex items-center btn ' + btn_class_name

    return (
        <button {...rest} className={className + ' ' + (props.className || '') + (size ? ' btn-' + size : '')} disabled={disabled}>
            {props.children}
            {(isLoading && <Spinner className="ml-2" />) || null}
        </button>
    )
})

export default Button
